


.bg_image {
    background-image: url("/public/attachments/bg.png");
    /* background: url(/public/attachments/bg.png) no-repeat center center;  */
  /* -webkit-background-size: cover; */
  /* -moz-background-size: cover; */
  /* -o-background-size: cover; */
  background-position: center;
  background-size: cover;
  min-height:200px
}