@import '~antd/dist/antd.css';
@import "./assets/fonts/stylesheet.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "SF Pro Display";
}

#root {
  height: 100%;
  width: 100%;
}


.fullPage {
  width: 100%;
  height: 100%;
}


.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}