@width: 20%;

@xs: ~"(max-width: 520px) and (min-width: 0px)";
@small: ~"(max-width: 699px) and (min-width: 520px)";
@small: ~"(max-width: 699px) and (min-width: 520px)";
@medium: ~"(max-width: 1000px) and (min-width: 700px)";
@large: ~"(min-width: 1001px)";
@xlarge: ~"(min-width: 1151px)";

@media @xs {
    .flexibleWidth {
        width: 100%;
    }
}

@media @small {
    .flexibleWidth {
        width: 100%;
    }
}

@media @medium {
    .flexibleWidth {
        width: 40%;
    }
}

@media @large {
    .flexibleWidth {
        width: 30%;
    }
}

@media @xlarge {
    .flexibleWidth {
        width: 20%;
    }
}


.flexibleWidth {
    width :30%;
}

.uploadField {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bgRed {
    background-color: red;
}

.contentCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}