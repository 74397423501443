.site-layout-background {
    background: #fff;
    transition: 300ms all;
}

.layoutHeader {
  height: 50px;
  padding: 0px;
background: rgb(228,29,40);
background: linear-gradient(6deg, rgba(228,29,40,1) 0%, rgba(228,29,40,0.75) 35%, rgba(228,29,40,0.6) 100%);

  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topBanner {
  background-color: #fff;
  padding: 10px 12px;
  margin-bottom: 5px;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}


@media (max-width: 425px) {
  .hideSidePanel {
    display: none;
  }
}


.flex-grid-view {
  display:flex;
  flex-direction: column;
  flex:1;
}